var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("img", {
        staticClass: "imgs",
        attrs: {
          src: "https://resource-file-dev.oss-cn-beijing.aliyuncs.com/dbe1734f-40b2-4121-a43a-4b7f56cc0dce",
          alt: "",
        },
      }),
      _c("p", [_vm._v("长按关注公众号～～")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }