<!-- 关注微信公众号 -->
<template>
    <div class='container'>
        <img class="imgs" src="https://resource-file-dev.oss-cn-beijing.aliyuncs.com/dbe1734f-40b2-4121-a43a-4b7f56cc0dce"
            alt="">
        <p>长按关注公众号～～</p>
        <!-- <van-button type="primary">调准</van-button> -->
    </div>
</template>

<script>
import Api from '@/components/Api/index.js'
import { getUrlParams } from '@/utils/unit'
export default {
    components: {},
    data() {
        return {

        }
    },
    computed: {},
    watch: {},
    methods: {

    },
    created() {

    },
    mounted() {
        let urlParams = getUrlParams('userId');
        if (urlParams) {
            new Api('user').authorizationCallbackAddressH5(urlParams).then(res => {
                console.log(res, '上看到看看打开的');
                if (res.status === 200) {
                    window.open(res.data, '_self')
                }
            })
        }
    },
}
</script>
<style lang='scss' scoped>
.container {
    padding: 1rem;

    .imgs {
        width: 100%;
        margin-bottom: 0.2rem;
    }
}
</style>
